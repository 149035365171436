.page {
    margin-left: 1rem;
    list-style-type: none;
  }
  
  .previous {
    display: none;
  }
  
  .next {
    display: none;
  }
  
  .active {
    color: #43A048 !important;
  }
  .paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    list-style-type: none;
  }
  .paginationLiTag {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paginationLink {
    display: flex;
    color: black;
    font-weight: bold;
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-top: 3px;
    border: 1px solid grey;
  }
  .activePaginationLink {
    display: flex;
    background: #43A048;
    color: white;
    font-weight: bold;
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-top: 3px;
    border: none;
  }
  .break {
    font-weight: bold;
    letter-spacing: 6px;
    margin-left: 2px;
  }
  @media only screen and (max-width: 700px) {
    .paginationLiTag {
      margin:4px;
    }
    .paginationLink{
      font-size: 15px;
      width: 30px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 360px) {
    .paginationLiTag {
      margin:3px;
    }
    .paginationLink{
      font-size: 10px;
      width: 25px;
      height: 25px;
    }
  }